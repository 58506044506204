import { connect } from 'react-redux';
import AuthForm from '../../components/auth/AuthForm/AuthForm';
import { login } from '../../actions/app';

const mapStateToProps = state => ({
  app: state.app,
  isLoading: state.loading.app,
  failedLogin: state.loading.failedLogin,
});

const mapDispatchToProps = {
  login,
};

const LoginView = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthForm);

export default LoginView;
