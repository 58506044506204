import * as React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './BaseDialog.module.css';
import { Dialog as DialogType, DialogTypes } from '../../types/dialog';
import Button from '../ui/Button/Button';

type Props = {
  dialog: DialogType;
  onClose: () => void;
};

function DefaultContent({ dialog, onAction, onClose }: any) {
  return (
    <DialogContent>
      <h4 className={styles.title}>{dialog.title || 'Action'}</h4>

      {dialog.content && <p className={styles.payload}>{dialog.content}</p>}

      <div className={styles.actions}>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onAction()}>Apply</Button>
      </div>
    </DialogContent>
  );
}

const NotificationDismissDialog: React.FunctionComponent<any> = React.lazy(() =>
  import('../Modals/DismissNotificationModal')
);

function BaseDialog(props: Props) {
  const { dialog, onClose } = props;

  function onAction() {
    if (dialog.actionFn) {
      dialog.actionFn();
    }
    onClose();
  }

  return (
    <Dialog
      open={dialog.isOpen}
      onClose={onClose}
      PaperProps={{ square: true }}
    >
      {dialog.isOpen && dialog.type === DialogTypes.DEFAULT && (
        <DefaultContent dialog={dialog} onAction={onAction} onClose={onClose} />
      )}

      <React.Suspense fallback={<span>...Loading</span>}>
        {dialog && dialog.type === DialogTypes.DISMISS_NOTIFICATION && (
          <DialogContent>
            <h4 className={styles.title}>{dialog.title || 'Action'}</h4>
            <NotificationDismissDialog
              notificationId={dialog.targetId}
              onSubmit={dialog.actionFn}
              onClose={onClose}
            />
          </DialogContent>
        )}
      </React.Suspense>
    </Dialog>
  );
}

export default BaseDialog;
