import * as React from 'react';

type Props = {
  classes?: any;
  type: string;
};

const Logo = ({ classes = {}, type = 'default' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 218 44"
    className={classes.icon}
    width="128"
    height="30"
  >
    <path
      d="M207 15.3l-4.2 5.6 4.2 5.6c.9 1.1.9 2.1-.1 3.1-1 .9-2 .9-2.9-.2l-3.8-5-3.8 5c-.9 1.1-1.9 1.1-2.9.2-1-1-1.1-2-.1-3.1l4.2-5.6-4.2-5.6c-.9-1.1-.9-2.1.1-3.1s2-.9 3 .2l3.8 5 3.8-5c.9-1.1 1.9-1.2 3-.2.8 1 .8 2-.1 3.1zm-25.1.7c-1.4 0-2 .7-2 2.1v5.7c0 1.5.7 2.2 2 2.2h2.8c1.4 0 2.1-.7 2.1-2.2V18c0-1.4-.7-2.1-2.1-2.1h-2.8zm.1-4.4h2.7c4.2 0 6.2 2.2 6.2 6.5v5.6c0 4.4-2.1 6.6-6.2 6.6h-2.8c-4.1 0-6.2-2.2-6.2-6.5v-5.7c.1-4.3 2.1-6.5 6.3-6.5zM167.3 26c1.4 0 2.1-.7 2.1-2.1v-5.8c0-1.5-.7-2.2-2.1-2.2h-4.8V26h4.8zm-9 2.1V8c0-1.4.7-2.1 2.1-2.1 1.4 0 2.1.7 2.1 2.2v3.6h4.9c4.1 0 6.2 2.2 6.2 6.6v5.6c0 4.3-2.1 6.5-6.2 6.5h-6.9c-1.5-.1-2.2-.8-2.2-2.3zm-2.1-14.3v15.9c0 4.2-1.9 6.8-6.1 6.8h-5c-1.3 0-2-.7-2-2.2 0-1.4.6-2.1 2-2.1h5.7c1 0 1.4-.9 1.4-1.9h-4.9c-4.1 0-6.2-2.2-6.2-6.6v-9.9c0-1.4.7-2.2 2.1-2.2 1.4 0 2.1.7 2.1 2.2v10c0 1.5.7 2.2 2.1 2.2h4.8V13.8c0-1.5.7-2.2 2.1-2.2 1.2 0 1.9.7 1.9 2.2zm-26.4 2.1c-1.4 0-2.1.7-2.1 2.2v5.6c0 1.5.7 2.2 2.1 2.2h4.8v-10h-4.8zm8.9-2.1v15.9c0 4.2-1.9 6.8-6.1 6.8h-5c-1.3 0-2-.7-2-2.2 0-1.4.6-2.1 2-2.1h5.7c1 0 1.4-.9 1.4-1.9h-4.9c-4.1 0-6.2-2.2-6.2-6.6v-5.5c0-4.4 2.1-6.5 6.3-6.5h6.9c1.3-.1 1.9.6 1.9 2.1zm-18.6 5.7c-1.4 0-2.1-.7-2.1-2.2V16h-3.4c-1.4 0-2.1.7-2.1 2.2v10c0 1.5-.7 2.2-2.1 2.2-1.4 0-2.1-.7-2.1-2.2v-9.9c0-4.4 2.1-6.6 6.2-6.6h5.5c1.4 0 2.1.7 2.1 2.1v3.6c.1 1.4-.6 2.1-2 2.1zm-13.9 8.7c0 1.4-.7 2.1-2.1 2.1h-6.9c-4.1 0-6.2-2.2-6.2-6.6v-9.9c0-1.4.7-2.2 2.1-2.2h11c1.4 0 2.2.7 2.2 2.1 0 1.4-.7 2.1-2.1 2.1h-8.9v2.9h6.2c1.4 0 2.1.8 2.1 2.2 0 1.4-.7 2.1-2.1 2.1h-6.2v.7c0 1.5.7 2.2 2.1 2.2h6.9c1.2.1 1.9.9 1.9 2.3zm-32.7-.1V13.8c0-1.4.7-2.1 2.1-2.1h6.9c4.1 0 6.2 2.2 6.2 6.6v9.9c0 1.4-.7 2.2-2.1 2.2-1.4 0-2.1-.7-2.1-2.2v-10c0-1.5-.7-2.2-2.1-2.2h-4.8v12.2c0 1.5-.7 2.2-2.1 2.2-1.3-.1-2-.8-2-2.3zm-2.2.1c0 1.4-.7 2.1-2.1 2.1h-6.9c-4.1 0-6.2-2.2-6.2-6.6v-9.9c0-1.4.7-2.2 2.1-2.2h11c1.4 0 2.2.7 2.2 2.1 0 1.4-.7 2.1-2.1 2.1h-8.9v2.9h6.2c1.4 0 2.1.8 2.1 2.2 0 1.4-.7 2.1-2.1 2.1h-6.2v.7c0 1.5.7 2.2 2.1 2.2h6.9c1.2.1 1.9.9 1.9 2.3z"
      fill={type === 'inverted' ? '#ffffff' : '#333d47'}
    />
    <path
      d="M38.9 42.1h-36c-1.6 0-2.9-1.3-2.9-3V3c0-1.6 1.3-3 2.9-3h36c1.6 0 2.9 1.3 2.9 3v6.2h4.9c1.6 0 2.9 1.3 2.9 3 0 1.6-1.3 3-2.9 3h-7.9c-1.6 0-2.9-1.3-2.9-3V5.9h-30v30.2H36V21.5c0-1.6 1.3-3 2.9-3h7.9c1.6 0 2.9 1.3 2.9 3 0 1.6-1.3 3-2.9 3h-4.9v14.7c0 1.6-1.3 2.9-3 2.9"
      fill="#00A0AE"
    />
  </svg>
);

export default Logo;
