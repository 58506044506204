export enum Actions {
  SET_PAGE_TITLE = '@@pageInfo/SET_PAGE_TITLE',
  SET_PAGE_SUBTITLE = '@@pageInfo/SET_PAGE_SUBTITLE',
  SET_PAGE_ICON = '@@pageInfo/SET_PAGE_ICON',
  SET_PAGE_TYPE = '@@pageInfo/SET_PAGE_TYPE',
}

export const setPageTitle = (title: string) => ({
  type: Actions.SET_PAGE_TITLE,
  payload: title,
});

export const setPageSubtitle = (pageSubtitle: string) => ({
  type: Actions.SET_PAGE_SUBTITLE,
  payload: pageSubtitle,
});

export const setPageIcon = (icon: any) => ({
  type: Actions.SET_PAGE_ICON,
  payload: icon,
});

export const setPageType = (pageType: any) => ({
  type: Actions.SET_PAGE_TYPE,
  payload: pageType,
});
