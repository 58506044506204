export enum Actions {
  STATUS = '@@equipmentFilter/STATUS',
  TYPE = '@@equipmentFilter/TYPE',
  SPACE = '@@equipmentFilter/SPACE',
  TITLE = '@@equipmentFilter/TITLE',
}

export const setEquipmentStatusListFilter = (filterType: string) => ({
  type: Actions.STATUS,
  payload: filterType,
});

export const setEquipmentTypeListFilter = (equipmentTypeIds: number[]) => ({
  type: Actions.TYPE,
  payload: equipmentTypeIds,
});

export const setEquipmentSpaceListFilter = (filterType: string) => ({
  type: Actions.SPACE,
  payload: filterType,
});

// export const setEquipmentTitleFilter = (searchString: string) => ({
//   type: SET_EQUIPMENT_TITLE_FILTER,
//   payload: searchString,
// });
