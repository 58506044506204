import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './InputField.module.css';

type Props = {
  isMainTitle?: boolean;
  isTitle?: boolean;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  fullWidth?: boolean;
  name?: string;
  id?: string;
  error?: boolean;
  extraClassName?: string;
  onChange?: (ev: any) => void;
  onFocus?: (ev: any) => void;
  onKeyDown?: (ev: any) => void;
  autoFocus?: boolean;
};

const InputField = ({
  name,
  id,
  isMainTitle,
  isTitle,
  type = 'text',
  value,
  placeholder = '',
  disabled = false,
  error = false,
  extraClassName = '',
  onChange,
  onFocus,
  autoFocus,
  ...rest
}: Props) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    className={classNames(
      styles.input,
      error ? styles.errorInput : null,
      isTitle ? styles.isTitle : null,
      isMainTitle ? styles.isMainTitle : null,
      extraClassName !== '' ? extraClassName : null
    )}
    onChange={onChange}
    onFocus={onFocus}
    name={name}
    id={id}
    autoFocus={autoFocus}
    {...rest}
  />
);

export default InputField;
