export enum ResourceType {
  SITE = 'Site',
  EQUIPMENT = 'Equipment',
  SPACE = 'Space',
  SENSOR = 'Sensor',
  ORGANIZATION = 'Organization',
}

export type ResourcePath = {
  id: number;
  title: string;
  type: ResourceType;
  typeId?: any;
};
