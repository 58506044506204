import { Dialog } from '../types/dialog';

export enum Actions {
  DIALOG_OPEN = '@@dialog/DIALOG_OPEN',
  DIALOG_CLOSE = '@@dialog/DIALOG_CLOSE',
}

export const openDialog = (payload: Dialog) => ({
  type: Actions.DIALOG_OPEN,
  payload,
});

export const closeDialog = () => ({
  type: Actions.DIALOG_CLOSE,
});
