import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './AuthForm.module.css';
import Button from '../../ui/Button';
import Logo from '../../icons/energybox/Logo';
import LoginBackground from '../../../static/patterns/login_background.jpg';
import { FnLogin } from '../../../actions/app';
import InputField from '../../ui/InputField';
import FormGroup from '../../FormGroup';
import { RouteComponentProps } from '@reach/router';

type Props = {
  login: FnLogin;
  isLoading: boolean;
  failedLogin: string;
} & RouteComponentProps;

type State = {
  email: string;
  password: string;
};

class AuthForm extends React.Component<Props, State> {
  state = {
    email: '',
    password: '',
  };

  handleChange = (name: keyof State) => (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => this.setState({ [name]: ev.target.value } as any);

  validateForm = () => {
    const { email, password } = this.state;
    return email !== '' && password !== '';
  };

  handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.login(email, password);
  };

  render() {
    const { email, password } = this.state;
    const { isLoading, failedLogin } = this.props;

    const formValid = this.validateForm();

    return (
      <React.Fragment>
        <header className={styles.header}>
          <Logo type="inverted" />
          <h1>Energybox</h1>
        </header>
        <section
          style={{ backgroundImage: `url(${LoginBackground})` }}
          className={styles.wrapper}
        >
          <form className={styles.form} onSubmit={this.handleLogin}>
            <div className={styles.formInnerWrapper}>
              <h2>Welcome</h2>

              <p>Login to your account</p>

              <FormGroup>
                <InputField
                  name="email"
                  id="email"
                  disabled={isLoading}
                  type="email"
                  placeholder="Email"
                  value={email}
                  error={!!failedLogin}
                  onChange={this.handleChange('email')}
                />
              </FormGroup>

              <FormGroup>
                <InputField
                  name="password"
                  id="password"
                  disabled={isLoading}
                  type="password"
                  placeholder="Password"
                  value={password}
                  error={!!failedLogin}
                  onChange={this.handleChange('password')}
                />
              </FormGroup>

              <div
                className={classNames(
                  styles.formField,
                  styles.loginButtonWrapper
                )}
              >
                {failedLogin && (
                  <div className={styles.errorMessage}>
                    <p>{failedLogin}</p>
                  </div>
                )}

                <Button
                  fullWidth
                  type="submit"
                  disabled={isLoading || formValid === false}
                  isLoading={isLoading}
                >
                  Login
                </Button>
              </div>
            </div>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default AuthForm;
