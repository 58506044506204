import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Router } from '@reach/router';

import * as routes from '../routes';
import { isSignedIn } from '../reducers/app';
import DefaultLayout from './layout/DefaultLayout';
import LoginView from './views/LoginView';

type Props = {
  isSignedIn: boolean;
};

function App({ isSignedIn }: Props) {
  return (
    <Router>
      <LoginView path={routes.LOGIN} />
      {isSignedIn ? (
        <DefaultLayout path="/*" />
      ) : (
        <Redirect
          from="/*"
          to={`${routes.LOGIN}?next=${encodeURIComponent(
            window.location.pathname
          )}`}
          noThrow
        />
      )}
    </Router>
  );
}

const mapStateToProps = ({ app }) => ({
  isSignedIn: isSignedIn(app),
});

export default connect(
  mapStateToProps,
  null
)(App);
