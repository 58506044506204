import { Actions } from '../actions/resources';
import { Sensor } from '../types/sensors';
import { Equipment } from '../types/equipment';
import { Space } from '../types/space';
import { Organization } from '../types/organization';
import { ResourceType } from '../types/resource';

const initialState = {};

function mapTargetTypes(resource: Sensor | Equipment | Space | Organization) {
  switch (resource._entity) {
    case ResourceType.EQUIPMENT:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity,
        typeId: resource.typeId,
      };

    case ResourceType.ORGANIZATION:
      return null;

    default:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity,
      };
  }
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.PATH_SUCCESS:
      return {
        ...state,
        [action.resourceId]: action.payload
          .reverse()
          .map(r => mapTargetTypes(r))
          .filter(p => p),
      };

    default:
      return state;
  }
};
